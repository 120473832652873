.swiper-container {
  width: 500px;
}

.swiper-pagination {
  bottom: 0;
  padding-bottom: 10px;
}

.swiper-wrapper {
  padding-inline-start: 0;
}
